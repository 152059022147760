import React, { useState, useEffect, useRef } from "react";
import "../assets/Css/StockList.css";
import stockData from "../JSON/Tradingview.json";
import { BiReset } from "react-icons/bi";


const StockList = () => {
  const [allStocks, setAllStocks] = useState([]);
  const [urls, setUrls] = useState([]);
  const [closeTime, setCloseTime] = useState(10);
  const [onChangeTime, setOnChangeTime] = useState(10);
  const [isRunning, setIsRunning] = useState(false);
  const [checkedUrls, setCheckedUrls] = useState([]);
  const [isSelectAll, setIsSelectAll] = useState(false);
  const [selectedDate, setSelectedDate] = useState(null);
  const [searchTerm, setSearchTerm] = useState('');


  const [finniftySelectedDate, setFinniftySelectedDate] = useState(null)

  const [manageCallPut, setManageCallPut] = useState(false);

  const [CallNearestStrike, setCallNearestStrike] = useState(false);
  const [CallLevelStrike, setCallLevelStrike] = useState(false);
  const [PutNearestStrike, setPutNearestStrike] = useState(false);
  const [PutLevelStrike, setPutLevelStrike] = useState(false);
  const [optionsTimeMange, setOptionsTimeMange] = useState(0);

  const [processedUrls, setProcessedUrls] = useState([]);
  const [marketPrices, setMarketPrices] = useState({});

  const [callLevelValue, setCallLevelValue] = useState(3);
  const [putLevelValue, setPutLevelValue] = useState(3);


  const currentIndexRef = useRef(
    localStorage.getItem("currentIndex")
      ? parseInt(localStorage.getItem("currentIndex"), 10)
      : 0
  );

  // const ApiUrl = 'http://localhost:8089'
  const ApiUrl= 'https://stock.i2rtest.in'


  const fetchStockList = async () => {
    try {
      const response = await fetch(`${ApiUrl}/api/stock/list`);
      if (!response.ok) {
        throw new Error("Failed to fetch stock data");
      }
      const data = await response.json();
      setAllStocks(data)
      setUrls(data);
    } catch (err) {
      console.log(err.message);
    } finally {
      console.log(false);
    }
  };

  useEffect(() => {
    fetchStockList();
  }, []);

  // date start ------------------------------------
  const handleDateChange = (e) => {
    setSelectedDate(e.target.value);

  };

  const formatDate = () => {
    if (selectedDate) {
      const date = new Date(selectedDate);
      const year = String(date.getFullYear()).slice(-2);
      const month = String(date.getMonth() + 1).padStart(2, "0");
      const day = String(date.getDate()).padStart(2, "0");

      return `${year}${month}${day}`;
    }
    return "";
  };


  const formatDate2 = () => {
    if (finniftySelectedDate) {
      const date = new Date(finniftySelectedDate);
      const year = String(date.getFullYear()).slice(-2);
      const month = String(date.getMonth() + 1).padStart(2, "0");
      const day = String(date.getDate()).padStart(2, "0");

      return `${year}${month}${day}`;
    }
    return "";
  };

  const getLastThursdayOfMonth = () => {
    const today = new Date();
    const year = today.getFullYear();
    const month = today.getMonth() + 1; // Get next month
    const lastDayOfMonth = new Date(year, month, 0); // Last day of the current month

    // Loop back from the last day of the month until we find a Thursday
    while (lastDayOfMonth.getDay() !== 4) {
      lastDayOfMonth.setDate(lastDayOfMonth.getDate() - 1);
    }

    return lastDayOfMonth;
  };

  useEffect(() => {
    const lastThursday = getLastThursdayOfMonth();
    const formattedDate = new Date(lastThursday.getTime() - lastThursday.getTimezoneOffset() * 60000)
      .toISOString()
      .split("T")[0];
    setSelectedDate(formattedDate);
  }, [])

  // date end ------------------------------------

  useEffect(() => {
    let interval;

    const executeMainOperation = async () => {
      if (isRunning && checkedUrls.length > 0) {
        if (!manageCallPut) {

          const currentUrl = checkedUrls[currentIndexRef.current];

          const symbolToUseEquity =
            currentUrl?.Symbol === "BAJAJ-AUTO"
              ? "BAJAJ_AUTO"
              : currentUrl?.Symbol;

          const fullUrl = `https://in.tradingview.com/chart/?symbol=NSE%3A${symbolToUseEquity}`;

          const newTab = window.open(fullUrl, "_blank");

          const nextIndex = (currentIndexRef.current + 1) % checkedUrls.length;
          currentIndexRef.current = nextIndex;

          localStorage.setItem("currentIndex", nextIndex);

          setTimeout(() => {
            newTab.close();
            // setProcessedUrls((prev) => [...prev, currentUrl?.Symbol]);
            setProcessedUrls((prev) => [...prev, symbolToUseEquity]);

            if (nextIndex === 0) {
              stopLoop();
            }
          }, closeTime * 1000);


        } else {

          const currentUrl = checkedUrls[currentIndexRef.current];


          // manage urls start------------------------------------
          // const allowedSymbolsMapping = {
          //   "NIFTY": "%5ENSEI",
          //   "BANKNIFTY": "%5ENSEBANK",
          //   "CNXFINANCE": "NIFTY_FIN_SERVICE.NS",
          //   "MIDCPNIFTY": "NIFTY_MID_SELECT.NS",
          //   "M_M": "M&M.NS",
          //   "M_MFIN": "M&MFIN.NS"
          // };

          // // Determine if currentUrl.Symbol is in allowedSymbolsMapping
          // let symbolResult;
          // if (allowedSymbolsMapping[currentUrl?.Symbol]) {
          //   symbolResult = allowedSymbolsMapping[currentUrl?.Symbol];
          // } else {
          //   symbolResult = null;
          // }
          // // manage urls end--------------------------------


          // const allowedSymbols = ["NIFTY", "BANKNIFTY", "CNXFINANCE", "MIDCPNIFTY", "M_M", "M_MFIN"];

          // // const proxyUrl = 'https://cors-anywhere.herokuapp.com/'; // CORS Proxy
          // const proxyUrl = 'https://thingproxy.freeboard.io/fetch/';
          // const url = allowedSymbols.includes(currentUrl?.Symbol)
          //   ? `https://query1.finance.yahoo.com/v8/finance/chart/${symbolResult}?region=IN&lang=en-IN&interval=1d`
          //   : `https://query1.finance.yahoo.com/v8/finance/chart/${currentUrl?.Symbol}.NS?region=IN&lang=en-IN&interval=1d`;

          // // const url = allowedSymbols.includes(currentUrl?.Symbol)
          // //   ? `https://api.allorigins.win/get?url=https://query1.finance.yahoo.com/v8/finance/chart/${symbolResult}?region=US&lang=en-US&interval=1d`
          // //   : `https://api.allorigins.win/get?url=https://query1.finance.yahoo.com/v8/finance/chart/${currentUrl.Symbol}.NS?region=US&lang=en-US&interval=1d`

          // const fetchUrl = proxyUrl + url;

          // const ApiUrl = 'http://localhost:8089/api/marketprice-stock'
          // const ApiUrl= 'https://stock.i2rtest.in/api/marketprice-stock'


          try {
            const response = await fetch(`${ApiUrl}/api/marketprice-stock/${currentUrl?.Symbol}`);
            if (!response.ok) {
              throw new Error('Network response was not ok');
            }
            const data = await response.json();

            const marketPrice = data.chart.result[0].meta?.regularMarketPrice;

            const Checklower = Math.floor(marketPrice / currentUrl.Range) * currentUrl.Range;
            const Checkhigher = Math.ceil(marketPrice / currentUrl.Range) * currentUrl.Range;

            const lower = [];
            for (let i = 1; i <= 4; i++) {
              lower.push(Checkhigher - i * currentUrl.Range);
            }

            const higher = [];
            for (let i = 1; i <= 4; i++) {
              higher.push(Checklower + i * currentUrl.Range);
            }

            var combinedArray = [...lower.slice(0, 1), ...higher.slice(0, 1)];

          } catch (err) {
            console.error('Error fetching data:', err);
          }


          if (!selectedDate && (!finniftySelectedDate || currentUrl?.Symbol === "CNXFINANCE")) {
            alert("Please select the appropriate expiry date first.");
            return;
          }

          if (currentUrl?.Symbol === "CNXFINANCE" && !finniftySelectedDate) {
            alert("Please select the FINNIFTY current expiry date.");

            const nextIndex = (currentIndexRef.current + 1) % checkedUrls.length;
            currentIndexRef.current = nextIndex;
            localStorage.setItem("currentIndex", nextIndex);

            if (nextIndex === 0) {
              stopLoop();
            }
            return;
          }


          // date diffrent ---------------------------------------------

          const symbolToUse =
            currentUrl?.Symbol === "CNXFINANCE"
              ? "FINNIFTY"
              : currentUrl?.Symbol === "MIDCPNIFTY1!"
                ? "MIDCPNIFTY"
                : currentUrl?.Symbol;

          const formattedDate = symbolToUse === "FINNIFTY" ? formatDate2(finniftySelectedDate) : formatDate(selectedDate);
          const optionBasicKeywordCall = `${symbolToUse}${formattedDate}C`;
          const optionBasicKeywordPut = `${symbolToUse}${formattedDate}P`;


          const openTabSequentially = async (optionKeyword, closeTime) => {
            return new Promise((resolve) => {
              const url = `https://in.tradingview.com/chart/?symbol=NSE%3A${optionKeyword}`;
              const newTab = window.open(url, "_blank");

              // Close tab after closeTime seconds
              setTimeout(() => {
                if (newTab) newTab.close();
                // setProcessedUrls((prev) => [...prev, currentUrl?.Symbol]);
                setProcessedUrls((prev) => [...prev, symbolToUse]);
                resolve();
              }, closeTime * 1000);
            });
          };

          const openConditionalUrl = async () => {
            if (combinedArray?.length > 1) {
              // Open CallNearestStrike first
              if (CallNearestStrike) {
                let selectedNumber = combinedArray[1];
                const optionKeyword = `${optionBasicKeywordCall}${selectedNumber}`;
                await openTabSequentially(optionKeyword, closeTime);
              }

              // Then open CallLevelStrike after PutNearestStrike is closed
              if (CallLevelStrike) {
                let selectedNumber = combinedArray[1] + callLevelValue * currentUrl.Range;
                const optionKeyword = `${optionBasicKeywordCall}${selectedNumber}`;
                await openTabSequentially(optionKeyword, closeTime);
              }

              // Then open PutNearestStrike after CallNearestStrike is closed
              if (PutNearestStrike) {
                let selectedNumber = combinedArray[0];
                const optionKeyword = `${optionBasicKeywordPut}${selectedNumber}`;
                await openTabSequentially(optionKeyword, closeTime);
              }

              // Finally, open PutLevelStrike after CallLevelStrike is closed
              if (PutLevelStrike) {
                let selectedNumber = combinedArray[0] - putLevelValue * currentUrl.Range;
                const optionKeyword = `${optionBasicKeywordPut}${selectedNumber}`;
                await openTabSequentially(optionKeyword, closeTime);
              }
            } else {
              console.error('combinedArray does not have enough elements:', combinedArray);
            }
          };

          // Validations before opening URLs
          if (!selectedDate) {
            alert("Please select the current month expiry date to enable the options segment.");
          } else {
            openConditionalUrl();
          }

          const nextIndex = (currentIndexRef.current + 1) % checkedUrls.length;
          currentIndexRef.current = nextIndex;

          localStorage.setItem("currentIndex", nextIndex);

          if (nextIndex === 0) {
            stopLoop();
          }
        }
      }
    }



    if (isRunning && checkedUrls.length > 0) {

      if (!manageCallPut) {
        executeMainOperation();
        interval = setInterval(() => {
          executeMainOperation();
        }, (closeTime + 1) * 1000);
      } else if (CallNearestStrike || CallLevelStrike || PutNearestStrike || PutLevelStrike) {
        executeMainOperation();
        interval = setInterval(() => {
          executeMainOperation();
        }, (closeTime * optionsTimeMange) * 1000);
      } else {
        alert("Please select any options button.")
      }
    }


    return () => clearInterval(interval);
  }, [urls, closeTime, isRunning, manageCallPut]); // Add manageCallPut to dependencies


  const handleTimeSet = (e) => {
    const { value } = e.target;
    setOnChangeTime(value);
  };

  const startLoop = () => {
    if (checkedUrls && checkedUrls.length > 0) {
      setIsRunning(true);
      setCloseTime(parseInt(onChangeTime));
    } else {
      alert("Please select at least one stock.")
    }
  };

  const stopLoop = () => {
    setIsRunning(false);
    currentIndexRef.current = 0;
    localStorage.setItem("currentIndex", 0);
  };


  const resetData = () => {
    setCloseTime(10)
    setOnChangeTime(10)
    setCheckedUrls([])
    setManageCallPut(false)
    setCallNearestStrike(false)
    setCallLevelStrike(false)
    setPutNearestStrike(false)
    setPutLevelStrike(false)
    setIsSelectAll(false)
    setSearchTerm("")
    setProcessedUrls([])
    setCallLevelValue(3)
    setPutLevelValue(3)
    fetchStockList();

    const lastThursday = getLastThursdayOfMonth();
    const formattedDate = new Date(lastThursday.getTime() - lastThursday.getTimezoneOffset() * 60000)
      .toISOString()
      .split("T")[0];
    setSelectedDate(formattedDate);
  }

  const handleCheckboxChange = (url) => {
    if (checkedUrls.some((checkedItem) => checkedItem.Symbol === url.Symbol)) {
      // Deselect
      setCheckedUrls(checkedUrls.filter((checkedItem) => checkedItem.Symbol !== url.Symbol));
    } else {
      // Select
      setCheckedUrls([...checkedUrls, url]);
    }
  };

  // Function to handle the "Select All" checkbox
  const handleSelectAll = (e) => {
    const isChecked = e.target.checked;
    setIsSelectAll(isChecked);

    if (isChecked) {
      setCheckedUrls(urls);
    } else {
      setCheckedUrls([]);
    }
  };

  const handleStockSearch = (e) => {
    const { value } = e.target;
    setSearchTerm(value);

    if (value === '') {
      setUrls(allStocks); // Set to the original full list of stocks
    } else {
      const filteredStocks = urls?.filter(stock =>
        stock.Symbol.toLowerCase().includes(value.toLowerCase()) ||
        stock.StockName.toLowerCase().includes(value.toLowerCase())
      );

      setUrls(filteredStocks);
    }
  }

  const handleEquityBtn = () => {
    setManageCallPut(false)
    setCallNearestStrike(false)
    setCallLevelStrike(false)
    setPutNearestStrike(false)
    setPutLevelStrike(false)
  };

  const handleOptionBtn = () => {
    if (selectedDate) {
      setManageCallPut(true)
    } else {
      alert("Please select current month expiry date to enable options segment.");
    }
  }

  useEffect(() => {
    let result;

    if (CallNearestStrike && CallLevelStrike && PutNearestStrike && PutLevelStrike) {
      result = 4;  // All 4 are true
    } else if (
      (CallNearestStrike && CallLevelStrike && PutNearestStrike) ||
      (CallNearestStrike && CallLevelStrike && PutLevelStrike) ||
      (CallNearestStrike && PutNearestStrike && PutLevelStrike) ||
      (CallLevelStrike && PutNearestStrike && PutLevelStrike)
    ) {
      result = 3;  // Exactly 3 are true
    } else if (
      (CallNearestStrike && CallLevelStrike) ||
      (CallNearestStrike && PutNearestStrike) ||
      (CallNearestStrike && PutLevelStrike) ||
      (CallLevelStrike && PutNearestStrike) ||
      (CallLevelStrike && PutLevelStrike) ||
      (PutNearestStrike && PutLevelStrike)
    ) {
      result = 2;  // Exactly 2 are true
    } else if (
      CallNearestStrike || CallLevelStrike || PutNearestStrike || PutLevelStrike
    ) {
      result = 1;  // Exactly 1 is true
    } else {
      result = 0;  // None are true
    }

    setOptionsTimeMange(result);

  }, [CallNearestStrike, CallLevelStrike, PutNearestStrike, PutLevelStrike])





  const [index, setIndex] = useState(0);

  const fetchData = async (Symbol) => {
    try {
      const response = await fetch(`${ApiUrl}/api/marketprice-stock/${Symbol}`);
      const data = await response.json();

      if (
        data.chart &&
        data.chart.result &&
        data.chart.result[0]?.meta?.regularMarketPrice
      ) {
        const marketPrice = data.chart.result[0].meta;

        setMarketPrices((prevPrices) => ({
          ...prevPrices,
          [Symbol]: marketPrice
        }));
        return; // Exit on success
      } else {
        // console.warn(`Market price data not available for ${currentUrl.Symbol}`);
        return;
      }

    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };



  // useEffect(() => {
  //   const data = urls;
  //   const interval = setInterval(() => {
  //     fetchData(data[index]?.Symbol);
  //     if (index == urls?.length - 1) {
  //       clearInterval(interval);
  //     } else {
  //       setIndex((prevIndex) => prevIndex + 1);
  //     }
  //   }, 1000);

  //   return () => clearInterval(interval);
  // }, [index]);

  useEffect(() => {
    if (!urls || urls.length === 0) {
      console.error("The 'urls' array is empty or undefined.");
      return;
    }

    const interval = setInterval(() => {
      if (index < urls.length && urls[index]?.Symbol) {
        fetchData(urls[index]?.Symbol);
        setIndex((prevIndex) => prevIndex + 1);
      } else {
        clearInterval(interval);
      }
    }, 1000);

    return () => clearInterval(interval); // Cleanup interval on unmount
  }, [index, urls]);


  return (
    <>
      <div className="trading-view-container">
        <div>
          <div>
            <h2
              className="trading-view-title"
              style={{
                background: "#0096a9",
                padding: "20px",
                borderRadius: "10px",
                boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
              }}
            >
              TradingView - Equity and F&O Analysis Tool
            </h2>

            <div className="strategy-grid">
              <div className="strategy-item">
                <b>Strategy Builder</b>
                <div className="trading-view-timeout">
                  <label>Set time in seconds:</label>
                  <input
                    type="number"
                    min="1"
                    max="60"
                    value={onChangeTime}
                    onChange={handleTimeSet}
                  />
                </div>

                <div className="trading-view-timeout">
                  <label>Select current month expiry date:</label>
                  <input
                    type="date"
                    value={selectedDate}
                    onChange={handleDateChange}
                  />
                </div>

                <div className="trading-view-timeout">
                  <label>Select Fin Nifty current month expiry date:</label>
                  <input
                    type="date"
                    value={finniftySelectedDate}
                    onChange={(e) => setFinniftySelectedDate(e.target.value)}
                  />
                </div>
              </div>

              <div className="strategy-item">
                <b>Segment</b>

                <div className="trading-view-controls">
                  <button
                    className={
                      manageCallPut
                        ? "trading-stop-button"
                        : "trading-start-button"
                    }
                    onClick={handleEquityBtn}
                  >
                    Equity
                  </button>
                  <button
                    className={
                      manageCallPut
                        ? "trading-start-button"
                        : "trading-stop-button"
                    }
                    onClick={handleOptionBtn}
                  >
                    Options
                  </button>
                </div>
              </div>

              {manageCallPut && (
                <div className="strategy-item">
                  <div>
                    <b>Call</b>
                    <div className="trading-view-controls">
                      <button
                        className={
                          CallNearestStrike
                            ? "trading-start-button"
                            : "trading-stop-button"
                        }
                        onClick={() => setCallNearestStrike(!CallNearestStrike)}
                      >
                        Nearest Strike
                      </button>
                      <input type='number' value={callLevelValue} onChange={(e) => setCallLevelValue(e.target.value)} min="1" max="4" style={{ height: 'auto', width: '30px' }} />
                      <button
                        className={
                          CallLevelStrike
                            ? "trading-start-button"
                            : "trading-stop-button"
                        }
                        onClick={() => setCallLevelStrike(!CallLevelStrike)}
                      >
                        Level
                      </button>
                    </div>
                  </div>

                  <div style={{ marginTop: '8px' }}>
                    <b>Put</b>
                    <div className="trading-view-controls">
                      <button
                        className={
                          PutNearestStrike
                            ? "trading-start-button"
                            : "trading-stop-button"
                        }
                        onClick={() => setPutNearestStrike(!PutNearestStrike)}
                      >
                        Nearest Strike
                      </button>


                      <input type='number' value={putLevelValue} onChange={(e) => setPutLevelValue(e.target.value)} min="1" max="4" style={{ height: 'auto', width: '30px' }} />
                      <button
                        className={
                          PutLevelStrike
                            ? "trading-start-button"
                            : "trading-stop-button"
                        }
                        onClick={() => setPutLevelStrike(!PutLevelStrike)}
                      >
                        Level
                      </button>
                    </div>
                  </div>
                </div>
              )}
            </div>

            <div className="trading-view-controls">
              <button
                onClick={startLoop}
                // disabled={isRunning || checkedUrls.length === 0}
                className={
                  isRunning ? "trading-stop-button" : "trading-start-button"
                }
              >
                Start
              </button>
              <button
                onClick={stopLoop}
                disabled={!isRunning}
                className={
                  isRunning ? "trading-start-button" : "trading-stop-button"
                }
              >
                Stop
              </button>
              <button
                onClick={resetData}
                className="trading-start-button"
              >
                Reset
              </button>
            </div>
          </div>

          <div className="trading-view-file-upload">
            <h3>List of Stocks</h3>

            <div className="checkbox-search-container">
              <label className="select-all">
                <input
                  type="checkbox"
                  checked={isSelectAll}
                  onChange={handleSelectAll}
                />
                Select all
              </label>

              <label className="search-label">
                Search:
                <input
                  type="search"
                  value={searchTerm}
                  onChange={handleStockSearch}
                  placeholder="Stock Name, Symbol"
                  className="search-input"
                />
              </label>
            </div>

            <ul className="trading-view-file-list">
              {urls?.length === 0 ? (
                <li>No stocks found</li>
              ) : (
                urls?.map((url, index) => (
                  <li
                    key={index}
                    className={`${processedUrls.includes(url.Symbol)
                      ? "trading-view-file-item-green"
                      : marketPrices[url.Symbol]?.regularMarketPrice > marketPrices[url.Symbol]?.chartPreviousClose
                        // : index % 2 === 0
                        ? "trading-view-file-item-2"
                        : "trading-view-file-item"
                      }`}

                  >

                    <label
                      style={{
                        display: "flex",
                        gap: "5px",
                        cursor: "pointer",
                        alignItems: "flex-start",
                        justifyContent: "space-between",
                      }}
                    >
                      <input
                        type="checkbox"
                        checked={checkedUrls.some((checkedItem) => checkedItem.Symbol === url.Symbol)}
                        onChange={() => handleCheckboxChange(url)}
                      />
                      <div
                        style={{
                          display: "flex",
                          gap: "5px",
                          flexDirection: "column",
                          flexGrow: 1,
                        }}
                      >
                        <div
                          style={{
                            display: "flex",
                            gap: "10px",
                            cursor: "pointer",
                          }}
                        >
                          <div>
                            <i>{url?.StockName} ({url?.Symbol})</i>
                            {/* <i>{url?.StockName}</i> */}
                          </div>

                        </div>
                        {/* <div>{url?.Symbol}</div> */}
                        <div>
                          <b>
                            {marketPrices[url.Symbol] !== undefined
                              ? `${marketPrices[url.Symbol]?.regularMarketPrice}${marketPrices[url.Symbol]?.currency}`
                              : 'Price Not Found'}
                          </b>
                        </div>
                      </div>
                      <div>
                        <button onClick={() => fetchData(url?.Symbol)}><BiReset color='#454545' /></button>
                      </div>
                    </label>


                    <div className="previousClose-grid-container">
                      <div className="previousClose-grid-item text-start">Previous Close: &nbsp; <b>{marketPrices[url.Symbol] !== undefined ? `${marketPrices[url.Symbol]?.chartPreviousClose}` : ''}</b></div>
                    </div>

                    <div className="marketprice-grid-container">
                      <div className="marketprice-grid-item">Day Low:&nbsp; <b>{marketPrices[url.Symbol] !== undefined ? `${marketPrices[url.Symbol]?.regularMarketDayLow}` : ''}</b></div>
                      <div className="marketprice-grid-item">Day High:&nbsp; <b>{marketPrices[url.Symbol] !== undefined ? `${marketPrices[url.Symbol]?.regularMarketDayHigh}` : ''}</b></div>
                      <div className="fiftyTwo-grid-item">52 Low:&nbsp; <b>{marketPrices[url.Symbol] !== undefined ? `${marketPrices[url.Symbol]?.fiftyTwoWeekLow}` : ''}</b></div>
                      <div className="fiftyTwo-grid-item">52 High:&nbsp; <b>{marketPrices[url.Symbol] !== undefined ? `${marketPrices[url.Symbol]?.fiftyTwoWeekHigh}` : ''}</b></div>
                    </div>
                  </li>
                ))
              )}
            </ul>
          </div>

          <div className="trading-view-controls">
            <button
              onClick={startLoop}
              disabled={isRunning || checkedUrls.length === 0}
              className={
                isRunning ? "trading-stop-button" : "trading-start-button"
              }
            >
              Start
            </button>
            <button
              onClick={stopLoop}
              disabled={!isRunning}
              className={
                isRunning ? "trading-start-button" : "trading-stop-button"
              }
            >
              Stop
            </button>
            <button
              onClick={resetData}
              className="trading-start-button"
            >
              Reset
            </button>
          </div>
        </div>
      </div>
    </>
  );
};

export default StockList;
