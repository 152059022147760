import React from "react";
import StockList from "./component/StockList";


function App() {
  return (
    <>


      <div className="App">
        <StockList />
      </div>

    </>
  );
}

export default App;
